/* General Tailwind Setup */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Starboy Animation */
@keyframes glitter {
    0%,
    100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.8;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg) translateX(50px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(50px) rotate(-360deg);
    }
}

.starboy-animation {
    position: relative;
    overflow: visible; /* Ensure stars are not clipped */
    z-index: 10; /* Bring the Starboy div and its contents in front of others */
}

.starboy-animation .star {
    position: absolute;
    width: 20px;
    height: 20px;
    fill: #fff;
    opacity: 0.8;
    z-index: 20; /* Ensure stars are above everything else */
    animation: glow infinite ease-in-out alternate;
}

.starboy-animation button {
    z-index: 15; /* Button is above other elements, but below stars */
}

/* Customize each star's glow cadence */
.starboy-animation .star:nth-child(1) {
    top: 10%;
    left: 20%;
    animation-duration: 3s;
    animation-delay: -1s;
}

.starboy-animation .star:nth-child(2) {
    top: 30%;
    right: 15%;
    animation-duration: 2.5s;
    animation-delay: 0s;
}

.starboy-animation .star:nth-child(3) {
    bottom: 20%;
    left: 25%;
    animation-duration: 4s;
    animation-delay: 0.5s;
}

.starboy-animation .star:nth-child(4) {
    bottom: 15%;
    right: 30%;
    animation-duration: 3.5s;
    animation-delay: -0.5s;
}

/* Glow animation keyframes */
@keyframes glow {
    0% {
        opacity: 0.5;
        filter: brightness(0.8);
    }
    50% {
        opacity: 1;
        filter: brightness(1.5);
    }
    100% {
        opacity: 0.5;
        filter: brightness(0.8);
    }
}

/* Flex God Animation */
.flex-god-animation {
    position: relative;
    overflow: visible; /* Ensure money emojis are not clipped */
    z-index: 10; /* Bring the Flex God div and its contents in front of others */
}

.flex-god-animation .money {
    position: absolute;
    font-size: 20px;
    opacity: 0.8;
    animation: glitter 2s infinite ease-in-out alternate;
    z-index: 20; /* Ensure money emojis are above everything else */
}

.flex-god-animation button {
    z-index: 15; /* Button is above other elements, but below money emojis */
}

.flex-god-animation .money:nth-child(1) {
    top: 10%;
    left: 20%;
    animation-duration: 3s;
    animation-delay: -1s;
    animation-name: rotate;
}

.flex-god-animation .money:nth-child(2) {
    top: 30%;
    right: 15%;
    animation-duration: 2.5s;
    animation-delay: 0s;
    animation-name: rotate;
}

.flex-god-animation .money:nth-child(3) {
    bottom: 10%;
    left: 15%;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-name: rotate;
}

.flex-god-animation .money:nth-child(4) {
    bottom: 15%;
    right: 20%;
    animation-duration: 3.5s;
    animation-delay: -0.5s;
    animation-name: rotate;
}

/* Ensure other option containers have a lower z-index */
.monk-mode-animation,
.ghost-mode-animation,
.other-options {
    z-index: 5;
}

/* for forecast */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 2s ease-in-out;
}
